import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

import cn from 'classnames';

/**
 * An encircled checkbox icon.
 * @component
 */
const Checkbox: VFC<ComponentPropsWithoutRef<'svg'>> = ({ className, ...props }): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('fill-white', className)}
      {...props}
    >
      <title>Checkbox</title>

      <circle cx="12" cy="12" r="12" fill="white" />
      <path d="M7 12.8L10.3333 16L17 8" stroke="#262626" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default Checkbox;
