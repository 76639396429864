import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

import cn from 'classnames';

/**
 * @component
 */
const Facebook: VFC<ComponentPropsWithoutRef<'svg'>> = ({ className, ...props }): ReactElement => {
  return (
    <svg
      width="30"
      className={cn('fill-black', className)}
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM20 9.15144V6.13702C19.375 6.04567 18.4954 6 17.3611 6C16.0262 6 14.9576 6.39203 14.1551 7.17608C13.3526 7.95252 12.9514 9.04868 12.9514 10.4645V12.9537H10V16.3335H12.9514V25H16.4931V16.3335H19.4329L19.8843 12.9537H16.4931V10.7957C16.4931 10.2476 16.6088 9.83654 16.8403 9.5625C17.0718 9.28846 17.5193 9.15144 18.1829 9.15144H20Z"
      />
    </svg>
  );
};

export default Facebook;
