import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'next-i18next';

import trackUserEvent from '@/util/eventTracking';

export default async (
  t: TFunction,
  { email }: { email: string },
  setLoading: Dispatch<SetStateAction<boolean>>,
  setFeedback: Dispatch<
    SetStateAction<
      | {
          text: string;
          className: string;
          final: boolean;
        }
      | undefined
    >
  >,
  setSubmitted?: Dispatch<SetStateAction<boolean>>,
  tag?: 'general' | 'options',
) => {
  trackUserEvent('[Newsletter Form] Validation Successful - Begin Newsletter Signup', {
    email,
  });
  setLoading(true);
  try {
    // @ts-ignore
    if (typeof window?.Moengage !== 'undefined') {
      // @ts-ignore
      window.Moengage.add_email(email);
      // @ts-ignore
      window.Moengage.add_user_attribute('EMAIL_SIGNUP_TAG', tag || 'none');
    }
    setLoading(false);
    setFeedback({
      text: t(
        tag === 'options'
          ? 'tickerboard.options_hype.form.feedback.success'
          : 'hero.form_email.feedback.success',
        {
          ns: 'homepage',
        },
      ),
      className: 'text-[#5EB242]',
      final: true,
    });
    trackUserEvent('[Newsletter Form] Submission Successful (Newsletter Subscribed)', {
      email,
    });
    return setSubmitted ? setSubmitted(true) : null;
  } catch (e: any) {
    setFeedback({
      text: t(
        tag === 'options'
          ? 'tickerboard.options_hype.form.feedback.error'
          : 'hero.form_email.feedback.error',
        {
          ns: 'homepage',
        },
      ),
      className: 'text-red-4',
      final: false,
    });
    trackUserEvent('[Newsletter Form] Error: Unhandled error during Mailchimp signup', {
      email,
    });
    setLoading(false);
    return setSubmitted ? setSubmitted(true) : null;
  }
};
