import { ComponentPropsWithoutRef, Fragment, ReactElement, useEffect, useState, VFC } from 'react';
// import { useTranslation } from 'next-i18next';
// import { useUser } from '@auth0/nextjs-auth0';
import trackUserEvent from '@/util/eventTracking';

// import Error from 'next/error';
import Link from 'next/link';
import cn from 'classnames';

import { Squeeze as Hamburger } from 'hamburger-react';

import {
  // RightArrow
  Button,
  Wordmark,
} from 'ui';

// import PromotionalBanner from '@/components/global/PromotionalBanner';

import useElementSize from '@/hooks/useElementSize';

/**
 * A dynamic list of the links of the site, generated from a static array.
 * @component
 */
const NavigationLinks: VFC<
  {
    className?: string;
    onClick?: () => void;
  } & ComponentPropsWithoutRef<'a'>
> = ({ className: componentClassName, onClick }): ReactElement => {
  // const { t } = useTranslation('common');

  const Links = [
    // {
    //   name: 'Solutions',
    //   href: '/',
    // },
    // {
    //   name: t('all_assets'),
    //   href: '/assets/all',
    // },
    // {
    //   name: t('stocks'),
    //   href: '/stock/all',
    // },
    // {
    //   name: t('crypto'),
    //   href: '/crypto/all',
    // },
    // {
    //   name: 'Mobile',
    //   href: '/mobile',
    // },
  ];

  return (
    <>
      {Links.map(({ name, href }) => (
        <Link key={name} href={href}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={cn(
              'rounded-lg shrink-0 border-0 text-body-3 text-center font-medium transition focus:outline-none hover:text-blue-3 focus:text-blue-3 active:text-blue-3',
              componentClassName,
            )}
            href="#"
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
            onClick={() => {
              if (onClick) onClick();
              trackUserEvent(`[Navigation] Link clicked`, {
                name,
                href,
              });
            }}
          >
            {name}
          </a>
        </Link>
      ))}
    </>
  );
};

/**
 * The main navigation bar of the site, including any promotional banners.
 */
const Navigation: VFC = (): ReactElement => {
  // const { user, error, isLoading } = useUser();

  /** Mobile menu open state */
  const [open, setOpen] = useState(false);

  /** We track the height of the navbar and any promotional bar combined so we can pad the mobile
   * menu to the same value.
   */
  const [barRef, { height }] = useElementSize();

  // if (error) return <Error statusCode={500} />;

  useEffect(() => {
    ['hidden', 'tablet:block'].forEach((className) => {
      document.getElementById('pageContent')?.classList?.toggle(className, open);
    });
  }, [open]);

  const isLoading = false;
  const user = undefined;

  return (
    <>
      {/* <style jsx global>{`
        #__next {
          margin-top: 79px;
        }

        @media screen and (min-width: 601px) {
          #__next {
            margin-top: 69px;
          }
        }
      `}</style> */}

      <style>{`
        #__next {
          // margin-top: 143px;
          margin-top: 79px;
        }
        @media screen and (min-width: 433px) {
          #__next {
            // margin-top: 121px;
            margin-top: 79px;
          }
        }
        @media screen and (min-width: 601px) {
          #__next {
            // margin-top: 111px;
            margin-top: 69px;
          }
        }
      `}</style>

      <div className="fixed top-0 left-0 z-50 w-full" ref={barRef}>
        {/* <PromotionalBanner
          onClick={() => {
            trackUserEvent(`[Navigation] Promotional banner clicked`);
          }}
          icon={<RightArrow className="flex-none fill-gray-1" />}
          className="relative"
        >
          {t('promotion', { spotsAvailable: '2,631' })}
        </PromotionalBanner> */}

        {/* The Header itself */}
        <header className="relative flex h-[79px] w-full items-center justify-center border-b border-gray-6 bg-white px-4 mobile:h-[69px] mobile:px-8 tablet:px-10">
          {/* Container */}
          <div className="flex w-full max-w-desktop items-center justify-between">
            <div className="flex flex-1 items-center">
              <Link href="/">
                <a
                  aria-label="Structure"
                  className="mr-auto transition-colors duration-300 focus:outline-none tablet:hover:fill-blue-3"
                >
                  <Wordmark className="!fill-[inherit]" />
                </a>
              </Link>
            </div>

            {/* Navigation & Onboarding (Logged Out) */}
            {!user && (
              <>
                <nav className="hidden flex-none items-center justify-center gap-6 tablet:flex">
                  <NavigationLinks />
                </nav>

                <div className="flex flex-1 items-center justify-end gap-2">
                  <a href="mailto:sales@structure.fi">
                    <Button
                      variant="primary"
                      size="small"
                      className="!px-[7px] nav-cta:!px-3"
                      style={{
                        minWidth: 'fit-content',
                      }}
                      onClick={() => {
                        trackUserEvent(`[Navigation] Sales CTA clicked`, {
                          state: open ? 'open' : 'closed',
                        });
                      }}
                    >
                      Contact sales
                    </Button>
                  </a>
                  <button
                    type="button"
                    onClick={() => {
                      setOpen((prev) => !prev);
                      trackUserEvent(`[Navigation] Mobile menu toggled`, {
                        state: open ? 'open' : 'closed',
                      });
                    }}
                    className="tablet:hidden"
                  >
                    <Hamburger toggled={open} direction="left" label="Open Menu" />
                  </button>
                </div>
              </>
            )}
          </div>
        </header>
      </div>

      {/* Mobile/Tablet Menu */}
      {open && !isLoading && (
        <nav className="fixed top-0 right-0 z-40 flex h-full min-h-[stretch] w-full justify-end tablet:hidden">
          <div
            style={{
              paddingTop: height,
            }}
            className="flex h-auto w-full flex-col justify-between px-4 pb-4 opacity-100 mobile:px-10"
          >
            <div className="flex flex-col gap-5 pt-6">
              <NavigationLinks
                onClick={() => setOpen(false)}
                className="!inline !text-left font-normal text-header-4"
              />
            </div>

            <div className="text-base flex flex-col gap-4">
              <h5 className="font-medium text-header-5">Integrate our tokenized assets today</h5>
              <a href="mailto:sales@structure.fi">
                <Button
                  variant="primary"
                  className="w-full"
                  onClick={() => {
                    setOpen(true);
                    trackUserEvent(`[Navigation] 'Contact sales' pressed (mobile menu)`);
                  }}
                >
                  Contact sales
                </Button>
              </a>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default Navigation;
