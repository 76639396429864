import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

import cn from 'classnames';

/**
 * A directionless arrow.
 * @component
 */
const Arrow: VFC<{ dir: 'left' | 'right' | 'up' | 'down' } & ComponentPropsWithoutRef<'svg'>> = ({
  className,
  dir,
  ...props
}): ReactElement => {
  return (
    <svg
      width="22"
      height="13"
      viewBox="0 0 22 13"
      className={cn('stroke-black', className, {
        'rotate-90': dir === 'down',
        'rotate-180': dir === 'left',
        'rotate-270': dir === 'up',
      })}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Arrow</title>

      <path d="M1 6.5H21M21 6.5L13 1M21 6.5L13 12" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Arrow;
