import createSocialIcon from './utils';

/**
 * @component
 */
const TelegramButton = createSocialIcon({
  d: 'M10.2941 18.7714C15.7821 16.2625 19.4417 14.6085 21.2727 13.8094C26.5007 11.5277 27.5871 11.1314 28.2951 11.1183C28.4509 11.1154 28.7991 11.1559 29.0246 11.348C29.2151 11.5101 29.2675 11.7292 29.2925 11.8829C29.3176 12.0366 29.3488 12.3869 29.324 12.6605C29.0407 15.7839 27.8148 23.3637 27.1912 26.862C26.9273 28.3422 26.4077 28.8386 25.9046 28.8871C24.8114 28.9927 23.9813 28.1291 22.9224 27.4008C21.2655 26.2611 20.3295 25.5517 18.7212 24.4396C16.8625 23.1545 18.0674 22.4481 19.1267 21.2937C19.4039 20.9916 24.2207 16.3945 24.3139 15.9774C24.3256 15.9253 24.3364 15.7309 24.2263 15.6282C24.1163 15.5255 23.9538 15.5607 23.8366 15.5886C23.6704 15.6281 21.0235 17.4639 15.8959 21.0957C15.1446 21.637 14.464 21.9008 13.8543 21.887C13.1822 21.8717 11.8892 21.4882 10.928 21.1603C9.74904 20.7582 8.81203 20.5456 8.89362 19.8627C8.93612 19.507 9.40295 19.1432 10.2941 18.7714Z',
});

export default TelegramButton;
