const path = require('path');

const languages = [
  // First one is default
  ['English', 'en-US'],
  // ['Français', 'fr-FR'],
  // ['Español', 'es-SV'],
  // ['Українська', 'uk-UA'],
  // ['Русский', 'ru-RU'],
];

// ['en-US', 'en-SV', ...]
const locales = languages.map((v, i) => languages[i][1]);

module.exports = {
  languages,
  i18n: {
    localePath: path.resolve('./public/locales'),
    locales,
    defaultLocale: locales[0],
  },
};
