import { IncomingMessage } from 'http';
import * as Sentry from '@sentry/nextjs';
import absoluteUrl from 'next-absolute-url';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import { API } from '@/types/app';

/**
 * A wrapper function for fetch that accepts relative URLs safely for SSR
 */
const APIFetcher = async (
  input: RequestInfo,
  init: RequestInit,
  req?: IncomingMessage & {
    cookies: NextApiRequestCookies;
  },
) => {
  return fetch(
    typeof window !== 'undefined'
      ? `${window.location.origin}${input}`
      : `${absoluteUrl(req, 'localhost:3000').origin}${input}`,
    init,
  )
    .then((response) => {
      return response.json();
    })
    .then((response: API.Response<any>) => {
      if (!response.success) {
        Sentry.captureException(
          new Error(
            response.error?.message || 'API Fetch returned a non-success value for "status".',
          ),
          {
            extra: { response },
          },
        );

        throw new Error(response.error?.message);
      }

      return response;
    });
};
export default APIFetcher;
