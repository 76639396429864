import createSocialIcon from './utils';

/**
 * @component
 */
const MailButton = createSocialIcon(
  {
    d: 'M29.38 13.5H10.62C10.2776 13.5 10 13.7776 10 14.12V25.88C10 26.2224 10.2776 26.5 10.62 26.5H29.38C29.7224 26.5 30 26.2224 30 25.88V14.12C30 13.7776 29.7224 13.5 29.38 13.5Z',
  },
  [
    {
      name: 'mail',
      d: 'M10.5 14L19.38 21.0108C19.7434 21.2976 20.256 21.2976 20.6193 21.0108L29.5 14',
      stroke: 'white',
      strokeLinecap: 'square',
    },
  ],
);

export default MailButton;
