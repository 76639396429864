import { useEffect, useRef } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { Button } from 'ui';
import classNames from 'classnames';

import useSessionStorage from '@/hooks/useSessionStorage';

import Popup from './global/Popup';

const LegalDisclaimer = () => {
  const router = useRouter();

  const [shouldBeShown, setShouldBeShown] = useSessionStorage<boolean>(
    'usDisclaimerShouldBeShown',
    true,
  );

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      containerRef.current?.scrollTo(0, 0);
    }, 5);
  }, []);

  return (
    <Popup
      containerClassName="!max-w-[580px] overflow-y-scroll"
      setOpen={() => {}}
      open={shouldBeShown && !router.pathname.includes('/legal/app/')}
    >
      <div className="mx-6 flex flex-col">
        <div className="mb-4 mt-6 flex flex-none items-center justify-between">
          <h2 className="text-[1.625rem] font-medium text-black">Legal disclaimer</h2>
        </div>

        <div className="max-h-[450px] flex-auto overflow-y-auto" ref={containerRef}>
          <p className="mb-5">
            The information presented on this website is not a solicitation to you or any other
            individual to purchase any financial product and/or service from us. Structure Financial
            tokenized assets may only be acquired and redeemed if the purchaser is not a United
            States citizen or resident of any state, possession or territory of the United States of
            America, an OFAC sanctioned country or individual or a resident of Iran, Syria, Cuba and
            North Korea.
          </p>

          <p className="mb-5">
            Structure Financial issued tokenized assets have not been and will not be registered
            under the U.S. Securities Act of 1933 or with any securities regulatory authority of any
            State or other jurisdiction and/or regulatory agency of the United States and (i) may
            not be offered, sold or delivered within the United States to, or for the account or
            benefit of U.S. Persons, and (ii) may be offered, sold or otherwise delivered at any
            time only to transferees that are Non-United States Person(s).”
          </p>

          <p className="mb-5">
            By clicking “I accept” in order to access the Structure Financial website, you
            acknowledge and agree (i) to each of the statements, disclosures and disclaimers
            contained herein, including that these materials herein (A) are for informational
            purposes only, (B) are not an offer or solicitation to buy or sell any security or
            financial service, and (C) should not be relied upon to make any investment decisions,
            and (ii) that you are not a United States citizen and do not reside in the United
            States. Our website is not intended for any other use other than the purposes described
            herein.
          </p>

          <p className="mb-5">
            The Terms of Service Agreement may be modified in the future from time to time without
            notice to you, so you should review and re-acknowledge each of the statements,
            disclosures and disclaimers contained herein each time you are asked to click “I
            accept”.
          </p>

          <p className="mb-10">
            Please also review{' '}
            <Link href="/legal/app/terms">
              <a className="text-blue-3">“Terms and Conditions of Use”</a>
            </Link>{' '}
            and our{' '}
            <Link href="/legal/app/privacy">
              <a className="text-blue-3">“Global Privacy Policy”</a>
            </Link>{' '}
            for additional terms and information regarding your access to the Structure website.
          </p>
        </div>

        <div
          className={classNames(
            'relative bottom-10 z-10 w-full max-w-[520px] h-10 bg-gradient-to-t from-white  pointer-events-none',
          )}
        />

        <div className="mb-5 flex flex-none justify-end">
          <Button
            className="grow bg-black text-white hover:text-white focus:text-white active:text-white disabled:bg-gray-3"
            onClick={() => setShouldBeShown(false)}
          >
            I accept
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default LegalDisclaimer;
