import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

import cn from 'classnames';

/**
 * A Share icon.
 * @component
 */
const Share: VFC<ComponentPropsWithoutRef<'svg'>> = ({ className, ...props }): ReactElement => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-black', className)}
      {...props}
    >
      <title>Share</title>
      <path
        d="M3 12V16C3 17.6569 4.34315 19 6 19H16C17.6569 19 19 17.6569 19 16V12"
        strokeLinecap="round"
      />
      <path
        d="M7 4.99988L11 0.999878M11 0.999878L15 4.99988M11 0.999878V11.9999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Share;
