import { ReactElement, VFC, ComponentPropsWithoutRef } from 'react';
import cn from 'classnames';

import { Dialog } from '@headlessui/react';
import useWindowSize from '@/hooks/useWindowSize';

/**
 * A reusable popup component
 * @component
 */
const Popup: VFC<
  {
    open: boolean;
    setOpen: Function;
    containerClassName?: string;
  } & ComponentPropsWithoutRef<'div'>
> = ({ children, className, containerClassName, open, setOpen }): ReactElement => {
  const { width } = useWindowSize();

  return (
    <Dialog
      className={cn(className, 'fixed inset-0 z-50 overflow-y-auto')}
      open={open}
      onClose={() => setOpen(false)}
    >
      {/* eslint-disable-next-line tailwindcss/no-contradicting-classname */}
      <div className="flex min-h-full min-h-[stretch] items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div
          className={cn(
            'relative z-[60] w-full h-screen align-bottom bg-white shadow-[0px_1px_8px_rgba(0,_0,_0,_0.1)] mobile:max-h-[fit-content] transition-all mobile:w-full mobile:max-w-[490px] mobile:h-[unset] mobile:align-middle mobile:rounded-xl',
            containerClassName,
          )}
          style={{
            height: width < 600 && (typeof window !== 'undefined' ? window.innerHeight : '100%'),
          }}
        >
          {children}
        </div>
      </div>
    </Dialog>
  );
};

export default Popup;
