import { ComponentPropsWithoutRef, Fragment, VFC } from 'react';
import cn from 'classnames';
import Link from 'next/link';

import Chevron from './icon/Chevron';

/**
 * A dynamic breadcrumbs component
 * @component
 */
const Breadcrumb: VFC<
  {
    crumbs: [string, string?][];
  } & ComponentPropsWithoutRef<'nav'>
> = ({ crumbs, className, ...props }) => {
  return (
    <nav className={cn('flex', className)} aria-label="Breadcrumb" {...props}>
      <ol className="flex items-center gap-2">
        {crumbs.map(([title, url]) => (
          <Fragment key={title}>
            <li>{url ? <Link href={url}>{title}</Link> : title}</li>
            <Chevron className="shrink-0 last:hidden" />
          </Fragment>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
