import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

/**
 * @component
 */
const Eye: VFC<ComponentPropsWithoutRef<'svg'>> = ({ className, ...props }): ReactElement => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.557609 8.22965C0.534297 8.13019 0.518234 8.05247 0.508063 8C0.518234 7.94754 0.534297 7.86981 0.557609 7.77035C0.605697 7.56517 0.684448 7.26828 0.805628 6.91033C1.04847 6.19302 1.45887 5.23891 2.12776 4.28759C3.45319 2.40254 5.80885 0.5 10 0.5C14.1911 0.5 16.5468 2.40254 17.8722 4.28759C18.5411 5.23891 18.9515 6.19302 19.1944 6.91033C19.3156 7.26828 19.3943 7.56517 19.4424 7.77035C19.4657 7.86981 19.4818 7.94754 19.4919 8C19.4818 8.05247 19.4657 8.13019 19.4424 8.22965C19.3943 8.43483 19.3156 8.73172 19.1944 9.08967C18.9515 9.80698 18.5411 10.7611 17.8722 11.7124C16.5468 13.5975 14.1911 15.5 10 15.5C5.80885 15.5 3.45319 13.5975 2.12776 11.7124C1.45887 10.7611 1.04847 9.80698 0.805628 9.08967C0.684448 8.73172 0.605697 8.43483 0.557609 8.22965Z"
        className="stroke-black"
      />
      <path
        d="M11.7223 8.00001C11.7223 8.9996 10.938 9.78572 10.0001 9.78572C9.06208 9.78572 8.27783 8.9996 8.27783 8.00001C8.27783 7.00041 9.06208 6.21429 10.0001 6.21429C10.938 6.21429 11.7223 7.00041 11.7223 8.00001Z"
        className="fill-black stroke-black"
      />
    </svg>
  );
};

export default Eye;
