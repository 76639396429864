import { ComponentPropsWithoutRef, Fragment, ReactElement, VFC } from 'react';
import { Tab } from '@headlessui/react';
import cn from 'classnames';

/**
 * A wrapper for `Tab` from `@headlessui/react`
 * @component
 */
const LinkTab: VFC<ComponentPropsWithoutRef<'button'>> = ({
  children,
  className,
  ...props
}): ReactElement => {
  return (
    <Tab as={Fragment}>
      {({ selected }) => (
        <button
          type="button"
          className={cn(
            'text-header-5 focus:outline-none whitespace-nowrap transition-colors duration-200',
            selected ? 'font-medium text-black border-b-2' : 'text-gray-4 hover:text-blue-3',
            className,
          )}
          {...props}
        >
          {children}
        </button>
      )}
    </Tab>
  );
};

export default LinkTab;
