import createButtonIcon from './utils';

/**
 * @component
 */
const FacebookButton = createButtonIcon({
  d: 'M23.9583 11.5476V14.1667H22.4008C21.832 14.1667 21.4484 14.2857 21.25 14.5238C21.0516 14.7619 20.9524 15.1191 20.9524 15.5952V17.4702H23.8591L23.4722 20.4068H20.9524V27.9365H17.9167V20.4068H15.3869V17.4702H17.9167V15.3075C17.9167 14.0774 18.2606 13.125 18.9484 12.4504C19.6363 11.7692 20.5523 11.4286 21.6964 11.4286C22.6687 11.4286 23.4226 11.4683 23.9583 11.5476Z',
});

export default FacebookButton;
