import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

import cn from 'classnames';

/**
 * @component
 */
const Plus: VFC<ComponentPropsWithoutRef<'svg'>> = ({ className, ...props }): ReactElement => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('stroke-black', className)}
      {...props}
    >
      <path d="M8 0L8 16M0 8L16 8" />
    </svg>
  );
};

export default Plus;
