import cn from 'classnames';
import { Toast, ToastBar } from 'react-hot-toast';

const renderToast = (t: Toast) => {
  const backgroundColor: string = {
    success: '#363636',
    error: '#E9664C',
  }[t.type];

  const paddingLeft = t.icon ? '8px' : '10px';

  const style = {
    borderRadius: 100,
    height: 48,
    padding: '8px 10px',
    paddingLeft,
    backgroundColor,
    fontSize: 15,
    color: 'white',
    lineHeight: '20px',
  };

  const iconBackgroundColor = {
    success: 'bg-[#8B8B8B]',
    error: 'bg-[#F78F7B]',
  }[t.type];

  const icon = t.icon ? (
    <div
      className={cn(
        'flex h-[32px] w-[32px] min-w-[32px] min-h-[32px] items-center justify-center rounded-full bg-white',
        iconBackgroundColor,
      )}
    >
      {t.icon}
    </div>
  ) : null;

  return <ToastBar toast={{ ...t, icon }} style={style} />;
};

export default renderToast;
