import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

import cn from 'classnames';

/**
 * @component
 */
const WiFi: VFC<ComponentPropsWithoutRef<'svg'>> = ({ className, ...props }): ReactElement => {
  return (
    <svg
      className={cn('stroke-black', className)}
      {...props}
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 5.5C1 5.5 4.16667 1 10.5 1C16.8333 1 20 5.5 20 5.5" strokeLinecap="round" />
      <path
        d="M4.61719 9.5C4.61719 9.5 6.42671 6.5 10.4981 6.5C14.5696 6.5 16.3791 9.5 16.3791 9.5"
        strokeLinecap="round"
      />
      <ellipse cx="10.5017" cy="13.5" rx="1.35714" ry="1.5" fill="#191919" />
    </svg>
  );
};

export default WiFi;
