import createButtonIcon from './utils';

/**
 * @component
 */
const LinkButton = createButtonIcon({
  d: 'M23.0475 16.9578L16.9603 23.045 M24.9836 22.2145L26.9204 20.2777C28.463 18.4584 28.3536 15.6236 26.367 13.637C24.3805 11.6504 21.5457 11.541 19.7264 13.0836L17.7895 15.0205 M22.2166 24.9815L20.2798 26.9183C18.4605 28.4609 15.6257 28.3515 13.6391 26.365C11.6525 24.3784 11.5431 21.5436 13.0857 19.7243L15.0226 17.7874',
  strokeWidth: '1.5',
});

export default LinkButton;
