import createSocialIcon from './utils';

/**
 * @component
 */
const LinkedInButton = createSocialIcon({
  d: 'M16.125 26V16.6562H13.2188V26H16.125ZM14.6562 15.4062C15.5938 15.4062 16.3438 14.625 16.3438 13.6875C16.3438 12.7812 15.5938 12.0312 14.6562 12.0312C13.75 12.0312 13 12.7812 13 13.6875C13 14.625 13.75 15.4062 14.6562 15.4062ZM26.9688 26H27V20.875C27 18.375 26.4375 16.4375 23.5 16.4375C22.0938 16.4375 21.1562 17.2188 20.75 17.9375H20.7188V16.6562H17.9375V26H20.8438V21.375C20.8438 20.1562 21.0625 19 22.5625 19C24.0625 19 24.0938 20.375 24.0938 21.4688V26H26.9688Z',
});

export default LinkedInButton;
