import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { appWithTranslation } from 'next-i18next';
import Router, { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import { SWRConfig } from 'swr';
import Link from 'next/link';
import Head from 'next/head';

import NextNProgress from 'nextjs-progressbar';
import { Toaster } from 'react-hot-toast';

import '@fontsource/ibm-plex-mono';

// import { UserProvider, useUser } from '@auth0/nextjs-auth0';

import trackUserEvent, { initialiseAmplitude } from '@/util/eventTracking';

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

import 'ui/src/style.css';
import '@/style/TypeAnimation.css';

import EmailForm from '@/components/homepage/forms/EmailForm';

import Navigation from '@/components/global/Navigation';
import APIFetcher from '@/util/APIFetcher';
import { API } from '@/types/app';
import { Wordmark } from 'ui';
import Script from 'next/script';

import renderToast from '@/util/renderToast';
import LegalDisclaimer from '@/components/LegalDisclaimer';
import { languages } from '../../next-i18next.config';

Sentry.init({
  dsn: 'https://5b1195a921824ccf809f832ad451c822@o1213162.ingest.sentry.io/6352717',

  // This enables automatic instrumentation (highly recommended), but is not
  // necessary for purely manual usage
  integrations: [new BrowserTracing()],

  beforeSend: (event, hint) => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(hint.originalException || hint.syntheticException);
      return null; // this drops the event and nothing will be sent to sentry
    }
    return event;
  },

  // To set a uniform sample rate
  tracesSampleRate: 0.2,
});

/**
 * Keeps the analytics API in sync with the auth state to enrich analytics data.
 */
const AnalyticsWrapper = ({ children }: { children: ReactNode }): ReactElement => {
  // const { user, error, isLoading } = useUser();
  const router = useRouter();
  const { locale, asPath } = router;

  useEffect(() => {
    const setupTracking = async () => {
      if (router.isReady) {
        await initialiseAmplitude();

        trackUserEvent('Page Change (Generic)', { url: asPath, locale });

        Router.events.on('routeChangeComplete', (url: string) => {
          if (!url.match(/\/assets\/(?!all)([a-zA-Z]+)+/i)) {
            trackUserEvent('Page Change (Generic)', { url, locale });
          }
        });
      }
    };

    setupTracking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (!isLoading) {
  //     if (user) {
  //       amplitude.getInstance().setUserId(user.sub);
  //     } else {
  //       // amplitude.getInstance().setUserId(null);
  //       // amplitude.getInstance().regenerateDeviceId();
  //     }
  //   }
  // }, [error, isLoading, user]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const MaintenenceWrapper = ({ children }: { children: ReactNode }): ReactElement => {
  // const { user, error, isLoading } = useUser();
  const router = useRouter();
  const [inMaintenence, toggleMaintenence] = useState<boolean>();

  useEffect(() => {
    async function checkStatus() {
      if (router.isReady) {
        await APIFetcher('/api/maintenence', {}).then(
          (res: API.Response<{ maintenence: boolean }>) => {
            if (res.result.maintenence) {
              toggleMaintenence(true);
            }
          },
        );
      }
    }

    checkStatus();
  }, [router]);

  if (inMaintenence) {
    return (
      <>
        <header className="relative flex h-[79px] w-full items-center justify-center border-b border-gray-6 bg-white px-4 mobile:h-[69px] mobile:px-8 tablet:px-10">
          {/* Container */}
          <div className="flex w-full max-w-desktop items-center justify-between">
            <div className="flex flex-1 items-center">
              <Link href="/">
                <a
                  aria-label="Structure"
                  className="mr-auto transition-colors duration-300 focus:outline-none tablet:hover:fill-blue-3"
                >
                  <Wordmark className="!fill-[inherit]" />
                </a>
              </Link>
            </div>
          </div>
        </header>

        <main className="flex flex-col items-center">
          <div className="mt-40 mb-25 flex w-full max-w-[670px] grow flex-col items-center px-10 text-center">
            <h1 className="mb-5 font-medium text-header-2 mobile:text-header-d-2">
              Temporarily under maintenance
            </h1>
            <p className="text-gray-4">
              Sorry, it looks like we&apos;re pushing some updates to the site, please check back
              later.
            </p>
          </div>
          <div className="mb-20 w-full max-w-[780px] grow">
            <section className="flex w-full items-center justify-center rounded-xl border border-gray-6 tablet:px-8">
              <div className="flex w-full flex-col tablet:max-w-[780px] tablet:flex-row tablet:justify-between tablet:gap-8">
                <aside className="flex shrink-0 justify-center rounded-t-xl bg-gray-7 px-11 pt-6 tablet:h-65 tablet:bg-transparent tablet:px-0">
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    alt="App Screenshot"
                    className="max-h-67 tablet:max-h-59"
                    src="/promotional/PhoneOffer.png"
                  />
                </aside>

                <main className="flex shrink flex-col px-4 pt-4 pb-6 mobile:px-6 mobile:pt-6 mobile:pb-8 tablet:max-w-[485px] tablet:justify-center tablet:px-0">
                  <h3 className="mb-2 font-medium text-black text-header-3 tracking-tight">
                    Join the next generation of investors
                  </h3>

                  <p className="mb-6 text-gray-1">
                    Trade tokenized stocks, ETFs, and crypto. No Trading fees.¹ Open 24 hours a day,
                    8 days a week.²
                  </p>

                  <EmailForm className="!max-w-[unset]" />
                </main>
              </div>
            </section>
          </div>
        </main>
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
/**
 * The main entrypoint to the app
 * @component
 */
const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  const site = 'https://structure.fi';
  const path = useRouter().asPath;

  return (
    // <UserProvider>
    <SWRConfig
      value={{
        fetcher: APIFetcher,
      }}
    >
      <NextNProgress color="#1C1C1C" height={1} />
      <AnalyticsWrapper>
        <Head>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=2" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=2" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=2" />
          <link rel="manifest" href="/site.webmanifest?v=2" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg?v=2" color="#1a1a1c" />
          <link rel="shortcut icon" href="/favicon.ico?v=2" />
          <meta name="apple-mobile-web-app-title" content="Structure" />
          <meta name="application-name" content="Structure" />
          <meta name="msapplication-TileColor" content="#1a1a1c" />
          <meta name="theme-color" content="#f5f6f7" />
          <title>Structure</title>
          <link rel="canonical" href={`${site}${path}`} />
          {languages.map((code) => (
            <link
              key={code[1]}
              rel="alternate"
              hrefLang={code[1]}
              href={`${site}/${code[1]}${path}`}
            />
          ))}
        </Head>
        <Script async src="https://www.googletagmanager.com/gtag/js?id=G-Z8GMH3Y7R8" />
        <Script
          id="pixel"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '3567394926882237');
            fbq('track', 'PageView');
            `,
          }}
        />
        <noscript>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            alt="Facebook Pixel"
            height="1"
            width="1"
            style={{ display: 'none' }}
            src="https://www.facebook.com/tr?id=3567394926882237&ev=PageView&noscript=1"
          />
        </noscript>

        <Script
          id="google-analytics"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
             window.dataLayer = window.dataLayer || [];
             function gtag(){dataLayer.push(arguments);}
             gtag('js', new Date());
 
             gtag('config', 'G-Z8GMH3Y7R8');
          `,
          }}
        />
        <Script
          id="moengage"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            (function(i,s,o,g,r,a,m,n){i.moengage_object=r;t={};q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute'],h={onsite:["getData","registerCallback"]};for(k
              in f){t[f[k]]=q(f[k])}for(k in h)for(l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);i.moe=i.moe||function(){n=arguments[0];return t};a.onload=function(){if(n){i[r]=moe(n)}}})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage')
            
              Moengage = moe({
                app_id:"AYGWPQ1DTFFK9FLF0ZABKPIQ",
                cluster: "DC_2",
                debug_logs: ${process.env.NODE_ENV === 'production' ? '0' : '1'}
              });            
          `,
          }}
        />

        <MaintenenceWrapper>
          <LegalDisclaimer />

          {!router.asPath.includes('/legal/app') && <Navigation />}
          {/* https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-props-no-spreading.md#when-not-to-use-it */}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <main className="h-full w-full bg-white" id="pageContent">
            <Component {...pageProps} />
          </main>
        </MaintenenceWrapper>
      </AnalyticsWrapper>
      <Toaster toastOptions={{ icon: null, position: 'bottom-left' }}>{renderToast}</Toaster>
    </SWRConfig>
    // </UserProvider>
  );
};

export default appWithTranslation(App);
