import React, { ComponentPropsWithoutRef, ReactElement } from 'react';

type Props = ComponentPropsWithoutRef<'svg'> & {
  size?: number;
  iconColor?: string;
  stroke?: string;
  fill?: string;
};

const createButtonIcon = (
  primaryPath: React.SVGProps<SVGPathElement>,
  additionalPaths?: ({ name: string } & React.SVGProps<SVGPathElement>)[],
) => {
  return ({
    className,
    iconColor = '#191919',
    stroke,
    fill = 'white',
    ...props
  }: Props): ReactElement => {
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}
      >
        <circle cx="20" cy="20" r="19.5" fill={fill} stroke={stroke ?? iconColor} />
        <path
          {...primaryPath}
          {...(primaryPath.strokeWidth ? { stroke: iconColor, fill: 'none' } : { fill: iconColor })}
        />
        {additionalPaths?.map(({ name, ...pathProps }) => (
          <path key={name} {...pathProps} />
        ))}
      </svg>
    );
  };
};

export default createButtonIcon;
