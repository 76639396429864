import { ReactElement, VFC } from 'react';
import cn from 'classnames';

export enum SortDirection {
  Up = 'Up',
  Down = 'Down',
}

const SortArrow: VFC<{
  className?: string;
  direction?: SortDirection;
  width?: number;
  height?: number;
}> = ({ className, direction = SortDirection.Down, ...rest }): ReactElement => {
  return (
    <svg
      className={cn(
        'transform',
        {
          'rotate-180': direction === SortDirection.Up,
        },
        'fill-black',
        className,
      )}
      width="9"
      height="7"
      viewBox="0 0 9 7"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Sort arrow pointing {direction}.</title>
      <path d="M3.76906 6.18421C4.09392 6.60526 4.90608 6.60526 5.23094 6.18421L8.88566 1.44737C9.21053 1.02632 8.80445 0.5 8.15472 0.5H0.84528C0.195552 0.5 -0.210527 1.02632 0.114337 1.44737L3.76906 6.18421Z" />
    </svg>
  );
};

export default SortArrow;
