import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

/**
 * A chevron pointing rightwards, used primarily in the `Breadcrumbs` component.
 * @component
 */
const Chevron: VFC<ComponentPropsWithoutRef<'svg'>> = ({ className, ...props }): ReactElement => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      className={className}
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Chevron</title>
      <path d="M1 1L8 8L1 15" strokeLinecap="round" />
    </svg>
  );
};

export default Chevron;
