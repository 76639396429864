import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';
import cn from 'classnames';

/**
 * A button that is styled to our DSM.
 * @component
 * @example
 * return (
 *   <Button type="button" variant="primary" small />
 * )
 */
const Button: VFC<
  {
    variant?: 'primary' | 'secondary' | 'link' | 'outline' | 'shadow' | 'success';
    size?: 'normal' | 'small' | 'circle';
  } & ComponentPropsWithoutRef<'button'>
> = ({
  children,
  className,
  variant = 'link',
  size = 'normal',
  disabled,
  type = 'button',
  ...props
}): ReactElement => {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      style={{
        minWidth: 'fit-content',
      }}
      className={cn(
        'rounded-lg border border-transparent text-center font-medium transition duration-200 focus:outline-none',
        {
          'bg-[black] text-white hover:bg-gray-2 focus:bg-gray-2 active:bg-gray-2':
            variant === 'primary',
        },
        {
          'bg-gray-8 text-black hover:bg-gray-7 focus:bg-gray-7 active:bg-gray-7':
            variant === 'secondary',
        },
        { 'cursor-not-allowed': disabled },
        {
          'hover:bg-black focus:bg-black active:bg-black': variant === 'primary' && disabled,
        },
        {
          'border border-black': variant === 'outline',
        },
        {
          'hover:text-blue-3 focus:text-blue-3 active:text-blue-3': variant === 'link',
        },
        {
          shadow: variant === 'shadow',
        },
        {
          'min-w-[97px] px-3 pt-[11px] pb-[12px] text-subtitle-2': size === 'small',
        },
        {
          'min-w-[104px] px-[22px] pb-[17px] pt-[16px] text-subtitle-1': size === 'normal',
        },
        {
          'flex h-8 w-8 items-center justify-center !rounded-full': size === 'circle',
        },
        {
          'bg-green-2 text-black': variant === 'success',
        },
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
