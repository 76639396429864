import { ComponentPropsWithoutRef, ReactElement, useState, VFC } from 'react';
import { useTranslation } from 'next-i18next';
import trackUserEvent from '@/util/eventTracking';

import { Formik, Form, Field } from 'formik';
import cn from 'classnames';

import * as Yup from 'yup';

import { Button } from 'ui';
import newsletterForm from '@/util/newsletterForm';

/**
 * A newsletter subscription form used under the options tab on the homepage
 * @component
 */
const EmailForm: VFC<ComponentPropsWithoutRef<'form'>> = ({
  className,
  ...formProps
}): ReactElement => {
  const { t } = useTranslation('homepage');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState<{
    text: string;
    className: string;
    final: boolean;
  }>();

  const errorMessage = t('tickerboard.options_hype.form.feedback.invalid');

  const FormSchema = Yup.object().shape({
    email: Yup.string().email(errorMessage).required(errorMessage),
  });

  const getButtonText = () => {
    if (loading) return '...';
    if (submitted) return t('tickerboard.options_hype.form.button_success');
    return t('tickerboard.options_hype.form.button');
  };

  return (
    <div className="flex w-full shrink-0 grow flex-col tablet:w-[unset]">
      <Formik
        initialValues={{
          email: '',
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={FormSchema}
        onSubmit={(props) => newsletterForm(t, props, setLoading, setFeedback, setSubmitted)}
      >
        {({ handleSubmit, errors, handleChange, values, touched }) => (
          <Form
            noValidate
            className={cn(
              'flex w-full flex-col gap-[8px] mobile:flex-row desktop:max-w-[466px]',
              className,
            )}
            onSubmit={handleSubmit}
            {...formProps}
          >
            <div className="flex w-full flex-col justify-start gap-[10px]">
              <Field
                className={cn(
                  `h-[57px] grow min-h-[57px] rounded-lg border-0 bg-gray-7 py-3 px-4 transition placeholder:text-black focus:placeholder:text-gray-5 focus:outline-none focus:ring-1 focus:ring-black mobile:flex-[5]`,
                  { 'ring-1 ring-red-4 focus:ring-red-4': errors.email },
                )}
                id="email"
                type="email"
                disabled={loading || feedback?.final}
                placeholder={t('tickerboard.options_hype.form.placeholder')}
                onChange={handleChange}
                value={values.email}
              />
              {errors.email && touched.email && <span className="text-red-4">{errors.email}</span>}
              {submitted && <span className={cn(feedback?.className)}>{feedback?.text}</span>}
            </div>
            <Button
              disabled={loading || feedback?.final}
              className="h-[57px]"
              onClick={() =>
                trackUserEvent('[Newsletter Form] Submission Attempt', {
                  location: 'assetDetails',
                })
              }
              type="submit"
              size="small"
              variant={submitted ? 'success' : 'primary'}
            >
              {getButtonText()}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmailForm;
