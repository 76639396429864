import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

import cn from 'classnames';

interface ArrowProps {
  className?: string;
  direction?: 'up' | 'down' | 'straight';
  width?: number;
  height?: number;
}

const TrendArrow: VFC<ArrowProps & ComponentPropsWithoutRef<'svg'>> = ({
  className,
  direction = 'up',
  width = 23,
  height = 16,
  ...props
}): ReactElement => {
  if (direction === 'straight')
    return (
      <svg
        width={width.toString()}
        height={height.toString()}
        viewBox="0 0 25 16"
        className={cn('fill-black', className)}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.999996 7.00007C0.447711 7.00007 -2.39682e-06 7.44779 -1.67323e-10 8.00008C2.39648e-06 8.55236 0.44772 9.00007 1 9.00007L0.999996 7.00007ZM11.65 8.00003L11.65 7.00003L11.65 7.00003L11.65 8.00003ZM24.7071 8.70724C25.0976 8.31672 25.0976 7.68355 24.7071 7.29303L18.3432 0.929013C17.9527 0.538486 17.3195 0.53848 16.929 0.929001C16.5385 1.31952 16.5385 1.95269 16.929 2.34321L22.5858 8.00012L16.9289 13.6569C16.5384 14.0474 16.5384 14.6806 16.9289 15.0711C17.3194 15.4617 17.9526 15.4617 18.3431 15.0711L24.7071 8.70724ZM1 9.00007L11.65 9.00003L11.65 7.00003L0.999996 7.00007L1 9.00007ZM11.65 9.00003L24 9.00013L24 7.00013L11.65 7.00003L11.65 9.00003Z"
          fill="black"
        />
      </svg>
    );

  return (
    <svg
      className={cn(
        'fill-black',
        className,
        {
          '-scale-y-100 fill-[#DB5940]': direction === 'down',
        },
        {
          'fill-[#5EB242]': direction === 'up',
        },
      )}
      width={width.toString()}
      height={height.toString()}
      viewBox="0 0 23 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Arrow trending {direction}</title>
      <path d="M0.180937 14.4263C-0.135911 14.8787 -0.0260595 15.5022 0.426297 15.8191C0.878653 16.1359 1.50222 16.0261 1.81906 15.5737L0.180937 14.4263ZM6.05447 7.78385L6.87353 8.35755L6.05447 7.78385ZM7.65233 7.73028L8.43113 7.103L7.65233 7.73028ZM10.8064 11.6463L10.0277 12.2736L10.8064 11.6463ZM12.3979 11.6017L13.2106 12.1845V12.1845L12.3979 11.6017ZM20.9867 0.837412C20.8969 0.292476 20.3823 -0.0764891 19.8374 0.013306L10.9572 1.4766C10.4122 1.5664 10.0433 2.08095 10.1331 2.62588C10.2229 3.17082 10.7374 3.53978 11.2823 3.44999L19.1759 2.14928L20.4766 10.0428C20.5664 10.5878 21.0809 10.9567 21.6259 10.8669C22.1708 10.7771 22.5398 10.2626 22.45 9.71766L20.9867 0.837412ZM1.81906 15.5737L6.87353 8.35755L5.23541 7.21015L0.180937 14.4263L1.81906 15.5737ZM6.87353 8.35755L10.0277 12.2736L11.5852 11.019L8.43113 7.103L6.87353 8.35755ZM13.2106 12.1845L20.8127 1.58273L19.1873 0.417269L11.5852 11.019L13.2106 12.1845ZM10.0277 12.2736C10.8572 13.3034 12.44 13.2591 13.2106 12.1845L11.5852 11.019L11.5852 11.019L10.0277 12.2736ZM6.87353 8.35755L6.87353 8.35755L8.43113 7.103C7.59584 6.06595 5.99936 6.11947 5.23541 7.21015L6.87353 8.35755Z" />
    </svg>
  );
};

export default TrendArrow;
