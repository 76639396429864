import createSocialIcon from './utils';

/**
 * @component
 */
const TwitterButton = createSocialIcon({
  d: 'M28.988 14.6863C28.4784 15.4316 27.8623 16.0667 27.1398 16.5915C27.1474 16.698 27.1512 16.8577 27.1512 17.0707C27.1512 18.0595 27.0067 19.0482 26.7176 20.037C26.4286 21.0181 25.9875 21.9612 25.3942 22.8663C24.8086 23.7714 24.1088 24.5738 23.295 25.2736C22.4812 25.9657 21.5 26.5209 20.3516 26.9393C19.2031 27.35 17.9747 27.5553 16.6665 27.5553C14.6054 27.5553 12.7191 27.0039 11.0078 25.9011C11.274 25.9315 11.5706 25.9467 11.8977 25.9467C13.609 25.9467 15.134 25.4219 16.4726 24.3723C15.674 24.3571 14.959 24.1137 14.3278 23.6421C13.6965 23.163 13.2629 22.5545 13.0272 21.8167C13.2781 21.8548 13.5101 21.8738 13.7231 21.8738C14.0501 21.8738 14.3734 21.8319 14.6928 21.7483C13.841 21.5733 13.1336 21.1512 12.5708 20.4819C12.0156 19.805 11.738 19.0216 11.738 18.1317V18.0861C12.2552 18.3751 12.8104 18.531 13.4036 18.5538C12.9017 18.2192 12.5024 17.7818 12.2057 17.2418C11.9091 16.7018 11.7608 16.1162 11.7608 15.4849C11.7608 14.8156 11.9281 14.1957 12.2628 13.6253C13.1831 14.7585 14.3011 15.6674 15.6169 16.3519C16.9404 17.0289 18.355 17.4054 19.861 17.4814C19.8001 17.1924 19.7697 16.911 19.7697 16.6372C19.7697 15.618 20.1272 14.7509 20.8421 14.036C21.5647 13.3134 22.4356 12.9521 23.4547 12.9521C24.5196 12.9521 25.417 13.34 26.1472 14.1158C26.9762 13.9561 27.7558 13.6595 28.486 13.226C28.2046 14.1006 27.6646 14.7775 26.8659 15.2567C27.5733 15.1807 28.2806 14.9905 28.988 14.6863Z',
});

export default TwitterButton;
