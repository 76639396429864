import {
  track,
  init,
  Identify,
  identify,
  getDeviceId,
  getSessionId,
} from '@amplitude/analytics-browser';

import changelog from 'changelog.json';

export const initialiseAmplitude = async () => {
  if (typeof window !== undefined) {
    // @ts-ignore
    await init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY).promise;

    const identifyObj = new Identify();
    identifyObj.set('Version', changelog.versions[changelog.latest]);
    identify(identifyObj);
  }
};

const trackUserEvent = async (title: string, metadata: object = null) => {
  const payload = {
    ...metadata,
  };

  await track(title, payload).promise;

  // @ts-ignore
  if (typeof window?.Moengage !== 'undefined') {
    // @ts-ignore
    window.Moengage.track_event(title, payload);
    // @ts-ignore
    window.Moengage.add_user_attribute('AMPLITUDE_SESSION_ID', getSessionId());
    // @ts-ignore
    window.Moengage.add_user_attribute('AMPLITUDE_DEVICE_ID', getDeviceId());
  }
};

export default trackUserEvent;
