import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

import cn from 'classnames';

/**
 * @component
 */
const Twitter: VFC<ComponentPropsWithoutRef<'svg'>> = ({ className, ...props }): ReactElement => {
  return (
    <svg
      width="30"
      className={cn('fill-black', className)}
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Twitter</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM21.6217 12.5036C22.3003 12.0107 22.8788 11.4143 23.3574 10.7143C22.6931 11 22.0288 11.1786 21.3645 11.25C22.1145 10.8 22.6217 10.1643 22.886 9.34284C22.2003 9.74998 21.4681 10.0286 20.6895 10.1786C20.0038 9.44998 19.161 9.08569 18.161 9.08569C17.2038 9.08569 16.386 9.42498 15.7074 10.1035C15.036 10.775 14.7003 11.5893 14.7003 12.5464C14.7003 12.8035 14.7288 13.0678 14.786 13.3393C13.3717 13.2678 12.0431 12.9143 10.8003 12.2786C9.56454 11.6357 8.51454 10.7821 7.65025 9.71784C7.33597 10.2536 7.17882 10.8357 7.17882 11.4643C7.17882 12.0571 7.31811 12.6071 7.59668 13.1143C7.87525 13.6214 8.25025 14.0321 8.72168 14.3464C8.16454 14.325 7.64311 14.1785 7.15739 13.9071V13.95C7.15739 14.7857 7.41811 15.5214 7.93954 16.1571C8.46811 16.7857 9.13239 17.1821 9.93239 17.3464C9.63239 17.425 9.32882 17.4643 9.02168 17.4643C8.82168 17.4643 8.60382 17.4464 8.36811 17.4107C8.58954 18.1035 8.99668 18.675 9.58954 19.125C10.1824 19.5678 10.8538 19.7964 11.6038 19.8107C10.3467 20.7964 8.91454 21.2893 7.30739 21.2893C7.00025 21.2893 6.72168 21.275 6.47168 21.2464C8.07882 22.2821 9.85025 22.8 11.786 22.8C13.0145 22.8 14.1681 22.6071 15.2467 22.2214C16.3253 21.8285 17.2467 21.3071 18.011 20.6571C18.7753 20 19.4324 19.2464 19.9824 18.3964C20.5395 17.5464 20.9538 16.6607 21.2253 15.7393C21.4967 14.8107 21.6324 13.8821 21.6324 12.9536C21.6324 12.7536 21.6288 12.6035 21.6217 12.5036Z"
      />
    </svg>
  );
};

export default Twitter;
