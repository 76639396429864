import { ComponentPropsWithoutRef, ReactElement, VFC } from 'react';

/**
 * @component
 */
const Link: VFC<ComponentPropsWithoutRef<'svg'>> = ({ fill, stroke, ...props }): ReactElement => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Link</title>
      <circle cx="15" cy="15" r="15" fill={fill || 'white'} />
      <path d="M17.2852 12.7183L12.7197 17.2837" stroke={stroke || '#191919'} />
      <path
        d="M18.7373 16.6609L20.19 15.2083C21.3469 13.8438 21.2648 11.7177 19.7749 10.2278C18.285 8.73786 16.1589 8.6558 14.7944 9.81275L13.3418 11.2654"
        stroke={stroke || '#191919'}
      />
      <path
        d="M16.6621 18.7361L15.2095 20.1887C13.845 21.3457 11.719 21.2636 10.229 19.7737C8.73908 18.2838 8.65702 16.1577 9.81397 14.7932L11.2666 13.3406"
        stroke={stroke || '#191919'}
      />
    </svg>
  );
};

export default Link;
