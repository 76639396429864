import createButtonIcon from './utils';

/**
 * @component
 */
const DiscordButton = createButtonIcon({
  d: 'M23.7263 23.0891C22.6184 23.0891 21.708 22.0853 21.708 20.8504C21.708 19.6154 22.5901 18.6027 23.7263 18.6027C24.8625 18.6027 25.7622 19.6154 25.7428 20.8504C25.7234 22.0853 24.8536 23.0891 23.7263 23.0891ZM16.2742 23.0891C15.1681 23.0891 14.2542 22.0853 14.2542 20.8504C14.2542 19.6154 15.1363 18.6027 16.2707 18.6027C17.4051 18.6027 18.3119 19.6154 18.2925 20.8504C18.2731 22.0853 17.4016 23.0891 16.2742 23.0891ZM27.7858 12.9237C26.3121 12.2491 24.7562 11.7705 23.1582 11.5C22.9395 11.891 22.7417 12.2932 22.5654 12.705C20.8631 12.4484 19.1321 12.4484 17.4298 12.705C17.2534 12.2932 17.0556 11.891 16.837 11.5C15.2379 11.7726 13.681 12.2524 12.206 12.9273C9.27736 17.2602 8.48346 21.4855 8.88041 25.6508C10.5954 26.918 12.5152 27.8816 14.5559 28.5C15.0153 27.8818 15.4219 27.2264 15.7714 26.54C15.1077 26.2921 14.4672 25.9862 13.8573 25.6261C14.0178 25.5097 14.1748 25.3897 14.3265 25.2732C17.9203 26.9632 22.0803 26.9632 25.674 25.2732C25.8275 25.3985 25.9845 25.5185 26.1433 25.6261C25.5322 25.9869 24.8903 26.2933 24.2254 26.5417C24.5745 27.2276 24.9812 27.8829 25.4411 28.5C27.4836 27.8841 29.4048 26.9208 31.1201 25.6526C31.5859 20.8221 30.3245 16.6356 27.7858 12.9237Z',
});

export default DiscordButton;
